import React, { useState, useEffect, useCallback } from 'react';

const ContentHub = () => {
    const [streams, setStreams] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const isPaused = false;

    const fetchStreams = useCallback(async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}`, {
                headers: {
                    'Authorization': 'Basic ' + btoa(`${process.env.REACT_APP_API_USER}:${process.env.REACT_APP_API_PASSWORD}`)
                }
            });
            const newStreams = await response.json();
            if (newStreams.length === 0) {
                setTimeout(() => fetchStreams(), 5000);
            }
            setStreams(newStreams);
        } catch (error) {
            console.error('Failed to update streams:', error);
        }
    }, []);

    /*
    const calculateTotalDuration = useCallback(() => {
        return streams.reduce((total, stream) => total + stream.DisplayFor, 0);
    }, [streams]);
    */
    useEffect(() => {
        fetchStreams();
    }, [fetchStreams]);

    useEffect(() => {
        if (!isPaused && streams.length > 0) {
            const timer = setTimeout(() => {
                const nextIndex = (currentIndex + 1) % streams.length;
                setCurrentIndex(nextIndex);

                if (nextIndex === 0) {
                    fetchStreams();
                }
            }, streams[currentIndex].DisplayFor);

            return () => clearTimeout(timer);
        }
    }, [currentIndex, isPaused, streams, fetchStreams]);

    /*
    const handlePauseToggle = () => {
        setIsPaused((prev) => !prev);
    };

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % streams.length);
    };

    const handlePrevious = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + streams.length) % streams.length);
    };
    */
    if (streams.length === 0) {
        return <div style={styles.loadingContainer}>Loading...</div>;
    }

    return (
        <div style={styles.container}>
            <iframe
                src={streams[currentIndex].URL}
                title={`Stream ${currentIndex + 1}`}
                style={styles.iframe}
                allowFullScreen
            />

            {/*
                <div style={styles.controlPanel}>
                    <div>Current: {currentIndex + 1}/{streams.length}</div>
                    <div>Cycle duration: {calculateTotalDuration() / 1000}s</div>
                    <div style={styles.buttonContainer}>
                        <button onClick={handlePrevious} style={styles.button}>Previous</button>
                        <button onClick={handlePauseToggle} style={styles.button}>
                            {isPaused ? 'Resume' : 'Pause'}
                        </button>
                        <button onClick={handleNext} style={styles.button}>Next</button>
                    </div>
                </div>
            */}
        </div>
    );
};

const styles = {
    container: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        backgroundColor: '#000',
    },
    iframe: {
        width: '100%',
        height: '100%',
        border: 'none',
    },
    controlPanel: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '10px',
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        color: 'white',
    },
    buttonContainer: {
        display: 'flex',
        gap: '10px',
    },
    button: {
        padding: '10px 20px',
        fontSize: '16px',
        backgroundColor: '#007bff',
        color: 'white',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        transition: 'background-color 0.3s',
    },
    loadingContainer: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#000',
        color: 'white',
        fontSize: '24px',
    },
};

export default ContentHub;